.PageTitle {
    display: flex;

    .nav-home {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;

        .dx-icon {

            font-size: 28px !important;

        }
    }
}