.blog-content {
    .ql-align-right {
        text-align: right;
    }

    .ql-align-center {
        text-align: center;
    }

    .ql-align-left {
        text-align: left;
    }

    .ql-align-justify {
        text-align: justify;
    }
}