.NavTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: rgba(222, 229, 7, 1);
    width: 290px;
    height: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    background-size: cover;
    margin-top: 8px;
    margin-right: 8px;

    .NavButton-Container {
        position: absolute;
        left: 50%;
        top: 90%;
    }
}

.NavTile:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.NavTile img {
    max-width: 100%;
    margin-bottom: 10px;
    // opacity: 0.8;
}

.NavTile h2 {
    font-size: 1.8rem;
    font-weight: 600;

    color: white;
    text-shadow: 0px 0px 20px rgb(187, 150, 107);
}

.NavTile p {
    font-size: 0.9rem;
    line-height: 1.2;
    text-align: center;
}

.NavTile .NavButton {
    padding: 5px;
    margin-top: 150px;
    z-index: 1;
}

.NavTileTitle {
    font-size: 0.6rem;
    margin-bottom: 5px;
    position: absolute;
    z-index: 1;
}