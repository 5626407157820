

.price-block-title {
    text-transform: uppercase
}

.price-table {
    border-collapse: collapse;
    width: 80%;
 
}
.bg-grey {
    background-color: rgb(235, 233, 233);
}

.border-right {
    border-right: 1px solid darkgray;
}
.price-table-header {
    border-bottom: 1px solid darkgray;
    text-align: left;
}
.price-col-1 {
    width: 40%;
}

.price-col-2 {
    width: 60%;
}

.fix-len {
    width: 150px;
}
 