.navigation-cards {
  align-items: center;
  //  background: linear-gradient(-45deg, #210242, #9198e5);
  display: flex;
  flex-wrap: wrap;
  //  height: 100vh;
  justify-content: center;
  margin: 0;
  //  width: 100vw;

}

.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;

  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;

    &.plus {
      margin: 0;
    }
  }
}

.daily-exercise-box {
  width: 350px;
  height: 600px;
}