.static-page {
    .title {
        font-weight: 400;
    }

    p {
        text-align: justify;
    }

    .buttons {
        align-content: center;
        vertical-align: middle;
    }

    .button {
        width: 180px;
        margin: 5px;
    }
}